import { PlcFlatRectLoader } from './PlcFlatRectLoader.component';
import React, { ComponentProps, FC } from 'react';

import './PlcImgLoader.scss';

const PlcImgLoader: FC<ComponentProps<typeof PlcFlatRectLoader> & { withSun?: boolean }> = ({
  withSun = true,
  uniqueKey,
  ...rest
}) => {
  return (
    <div className="plc-img-loader">
      <div className="wrapper">
        <PlcFlatRectLoader uniqueKey={uniqueKey} {...rest} />
        {withSun && (
          <svg className="illu" viewBox="0 0 345 135" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
              <path
                className="cloud"
                d="M180.377 18.034c1.191-.486 2.54-.75 3.892-.93 2.078-.275 91.586.07 93.728.197 2.138.124 7.242 1.593 8.916 2.673 1.956 1.26 3.327 3.222 3.327 3.222 1.287 2.442.686 3.965.048 5.51-.635 1.537-2.757 3.057-4.338 3.924-.589.323-1.906 1.061-2.644 1.144-5.385.602-98.64.058-103.304-2.329-2.335-1.196-3.665-2.852-4.684-4.744-1.338-2.48 1.777-7.333 5.06-8.667M33.893 22.034c.862-.486 1.84-.75 2.82-.93 1.502-.276 66.32.071 67.871.197 1.548.125 5.244 1.593 6.457 2.674 1.415 1.26 2.408 3.221 2.408 3.221.934 2.442.496 3.965.035 5.51-.46 1.537-1.995 3.056-3.14 3.924-.427.323-1.381 1.061-1.914 1.144-3.901.602-71.431.058-74.808-2.329-1.69-1.196-2.654-2.852-3.392-4.744-.969-2.48 1.286-7.333 3.663-8.667M163.45 118.094c.985-.51 2.1-.793 3.221-.983 1.718-.294 75.795.074 77.567.206 1.77.132 5.994 1.689 7.38 2.832 1.618 1.335 2.754 3.412 2.754 3.412 1.065 2.585.565 4.198.038 5.833-.525 1.629-2.28 3.235-3.59 4.155-.486.34-1.578 1.125-2.188 1.213-4.457.636-81.634.06-85.492-2.468-1.932-1.266-3.034-3.02-3.877-5.022-1.108-2.627 1.472-7.764 4.187-9.178"
                fill="#D5D6DF"
              />
              <path
                d="M146.055 25.833c-9.273 14.7-6.19 23.048-1.985 40.863 1.926 8.155 8.359 14.644 13.16 19.248 1.784 1.71 4.066 2.44 6.304 2.876 17.325 3.38 37.247 4.307 51.393-8.36 7.082-6.338 11.119-15.127 14.21-25.159 4.057-13.164-6.955-42.054-16.911-49.133-3.614-2.569-7.704-3.974-11.812-4.93-6.296-1.464-12.888-1.91-19.053.23-6.168 2.14-24.383 5.584-26.974 12.741l-8.332 11.624z"
                fill="#FFD314"
              />
              <path
                className="cloud"
                d="M291.902 85.276c-2.678-.599-5.712-.926-8.759-1.148-4.67-.34-206.066.088-210.883.242-4.813.156-16.296 1.968-20.064 3.305-4.4 1.556-7.485 3.978-7.485 3.978-2.898 3.018-1.543 4.9-.107 6.808 1.428 1.898 6.199 3.777 9.761 4.848 1.323.397 4.286 1.31 5.947 1.413 12.117.742 221.943.07 232.435-2.878 5.252-1.478 8.247-3.523 10.539-5.859 3.007-3.065-4-9.061-11.384-10.709M304.988 51.276c-2.217-.597-4.729-.926-7.25-1.147-3.865-.342-170.537.088-174.525.242-3.982.154-13.485 1.967-16.605 3.302-3.64 1.558-6.192 3.98-6.192 3.98-2.398 3.019-1.277 4.9-.089 6.807 1.18 1.9 5.13 3.776 8.078 4.848 1.094.398 3.546 1.314 4.92 1.413 10.03.744 183.68.071 192.362-2.878 4.347-1.477 6.823-3.524 8.722-5.859 2.489-3.066-3.31-9.06-9.421-10.708"
                fill="#D5D6DF"
              />
            </g>
          </svg>
        )}
      </div>
    </div>
  );
};

export { PlcImgLoader };
